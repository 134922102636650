<template>
 <b-sidebar v-model="show" @change="closeSider" right shadow backdrop bg-variant="white" header-class="container-title-sider" width="400px">
  <template #header>
    <div>
      <span class="title-sider">{{ $t('creator.titleSiderFilterCasting') }}</span>
      <feather-icon class="close-sider-network" icon="XIcon" size="20" @click="show = false" />
    </div>
  </template>
    <div class="mt-2 px-2 mb-2">
      <div>
        <h5 class="d-block mb-1">{{ $t('creator.compensation') }}</h5>
        <div class="d-flex mb-2">
          <b-form-group :label="$t('creator.minAmount')">
            <cleave :options="options_cleave.number" v-model="compensations_response_min" class="form-control"/>
          </b-form-group>
          <b-form-group :label="$t('creator.maxAmount')" class="ml-1">
            <cleave :options="options_cleave.number" v-model="compensations_response_max" class="form-control"/>
          </b-form-group>
        </div>

        <b-form-group :label="$t('creator.typeCompentation')">
          <b-form-checkbox 
          v-for="(checkbox, index) in $t('creator.compensations_type')"
          :key="index"
          class="mb-05" 
          v-model="compensations_type"
          :value="checkbox.value"
        >{{checkbox.text}}</b-form-checkbox>
        </b-form-group> 

        <b-form-group :label="$t('creator.audience')">
          <b-form-checkbox v-model="audience" value="public" class="content-checkbox">{{ $t('creator.public')}}</b-form-checkbox>
          <b-form-checkbox v-model="audience" value="private" class="content-checkbox">{{ $t('creator.private')}}</b-form-checkbox>
        </b-form-group>

        <b-form-group :label="$t('creator.dueDate')">
          <div class="fields">
            <b-form-datepicker v-model="date_init" class="size-inputs" :placeholder="$t('creator.startDate')"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es" />
            <b-form-datepicker v-model="date_end" class="size-inputs" :placeholder="$t('creator.endDate')"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="es" />
          </div>
        </b-form-group>
        <b-form-group :label="$t('creator.liveStatus')">
          <b-form-checkbox 
          v-for="(checkbox, index) in $t('creator.live_status')"
          :key="index"
          class="mb-05" 
          v-model="live_status"
          :value="checkbox.value"
        >{{checkbox.text}}</b-form-checkbox>
        </b-form-group>
      </div>
    </div>
    <template #footer>
      <div class="button-spacing">
        <b-button class="btn-clear" variant="outline-secondary" @click="resetForm()">
          <b-icon icon="trash" class="cursor-pointer" />
        </b-button>
        <b-button class="size-button" variant="primary" @click="applyFilters()">
          {{ $t('creator.updateResultFilters') }}
        </b-button>
      </div>
    </template>
 </b-sidebar>
</template>
<script>
import {
  BIcon,
  BSidebar,
  BFormGroup,
  BFormCheckbox,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import cleave from 'vue-cleave-component';
import casting_calls_services from '@/services/casting_calls';
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  name: "SiderFilter",
  components: {
    cleave,
    BIcon,
    BSidebar,
    BFormGroup,
    BFormCheckbox,
    BFormDatepicker,
    BButton,
  },
  props: {
    show_sider_filter: {
      type: Boolean,
    },
    filters_layout: {
      type: Array,
    },
  },
  data() {
    return {
      audience: [],
      live_status: [],
      compensations_type: [],
      date_init: '',
      date_end: '',
      compensations_response_min: 0,
      compensations_response_max: 0,
      options_cleave: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      show: this.show_sider_filter,
      filters: [
        {
          filter: 'audience',
          value: [],
        },
        {
          filter: 'live_status',
          value: [],
        },
        {
          filter: 'compensations_type',
          value: [],
        },
        {
          filter: 'date_init',
          value: '',
        },
        {
          filter: 'date_end',
          value: '',
        },
        {
          filter: 'compensations_response_min',
          value: 0,
        },
        {
          filter: 'compensations_response_max',
          value: 0,
        },
      ],
    };
  },
  created() {
    if (this.filters_layout.length > 0) {
      this.audience = this.filters_layout[0].value;
      this.live_status = this.filters_layout[1].value;
      this.compensations_type = this.filters_layout[2].value;
      this.date_init = this.filters_layout[3].value;
      this.date_end = this.filters_layout[4].value;
      this.compensations_response_min = this.filters_layout[5].value;
      this.compensations_response_max = this.filters_layout[6].value;
    }
  },
  methods: {
    resetForm() {
      this.audience = []
      this.live_status = []
      this.compensations_type = []
      this.date_init = ''
      this.date_end = ''
      this.compensations_response_min = 0
      this.compensations_response_max = 0
    },
    closeSider() {
      this.$emit("close_siderbar_filter");
    },
    applyFilters () {
      const filteredData = {};
      
      // Agregar solo los filtros que tienen datos
      if (this.audience !== []) filteredData.audience = this.filters[0].value = this.audience;
      if (this.live_status !== []) filteredData.statuses = this.filters[1].value = this.live_status;
      if (this.compensations_type !== []) filteredData.compensations_type = this.filters[2].value = this.compensations_type;
      if (this.date_init !== '') filteredData.date_init = this.filters[3].value = this.date_init;
      if (this.date_end !== '') filteredData.date_end = this.filters[4].value = this.date_end;
      if (this.compensations_response_min !== '') filteredData.compensations_response_min = this.filters[5].value = this.compensations_response_min;
      if (this.compensations_response_max !== '') filteredData.compensations_response_max = this.filters[6].value = this.compensations_response_max;
      casting_calls_services.castingFilters(filteredData)
        .then(response => {
          if (response.status === 400) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title:  this.$t('err'),
                text: this.$t('messageAccountSettingError'),
                icon: "CoffeeIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('creator.filterApply'),
                icon: 'CoffeeIcon',
                variant: 'success',
                text:  this.$t('creator.textfilter')
              }
            })
            this.$emit("update_casting", response.results, this.filters);
            this.show = false
          }
        })
    },
  }
};
</script>
<style>
.size-inputs {
  width: 45%;
}

.fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-checkbox {
  margin-bottom: 2px;
}

.button-spacing {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.size-button {
  width: 75%;
  height: 40px;
}

.btn-clear {
  width: 20%;
}

.btn-clear:focus {
  width: 20%;
  background: transparent !important;
}
</style>